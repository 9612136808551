.custom-label {
  display: block;
  color: #151a22;
  cursor: pointer;
  background: #fff;
  width: fit-content;
  font-size: 16px;
  border-bottom: none !important;
  position: relative;
}

.option {
  display: flex !important;
  column-gap: 10px;
  margin-bottom: 16px;
}

.css-xmrady-Accordion,
.accordion-wrapper > div {
  border: 1px solid #dee3ed !important;
  border-radius: 8px;
}
