.app-image-gallery {
  max-width: 350px;
  width: 100%;
}

.gallery-container {
  margin-top: 50px;
}

.video-wrapper {
  position: relative;
  background: #000;
  padding: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 350px;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-wrapper iframe {
  min-width: 350px;
  max-width: 50%;
  min-height: 350px;
}

.video-wrapper {
  width: 350px;
  height: 350px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-gallery-svg {
  width: 20px !important;
  height: 30px !important;
}

.image-gallery-slide {
  max-height: 350px;
}

.image-gallery-swipe {
  border-radius: 8px;
  overflow: hidden;
}

.image-gallery-image {
  min-height: 350px;
  object-fit: cover !important;
}

.app-image-gallery .image-gallery-thumbnails {
  padding: 16px 0px;
}

.image-gallery-thumbnail {
  width: 73px !important;
  height: 73px !important;
  border-radius: 7px;
  overflow: hidden;
  border: 1px solid transparent !important;
}

.image-gallery-thumbnail.active {
  border: 1px solid #2b50c8 !important;
}

.image-gallery-thumbnail-inner {
  display: contents !important;
}

.image-gallery-thumbnail-image {
  height: 100%;
  object-fit: cover;
}

.image-gallery-thumbnails-container > :not(:last-child) {
  margin-right: 16px;
}

.gallery .image-gallery-slide-wrapper {
  display: inline-flex;
  min-width: 350px;
  max-width: 50%;
}

.gallery .image-gallery-thumbnails-wrapper {
  padding-top: 36px;
  display: inline-flex;
  width: 50%;
  position: fixed;
  padding-left: 24px;
}

.gallery .image-gallery-thumbnails .image-gallery-thumbnails-container {
  display: contents;
}

.gallery .image-gallery-thumbnail {
  margin-right: 10px;
  margin-top: 10px;
}

.gallery .image-gallery-thumbnails {
  margin-top: -15px;
}
